import { Injectable } from '@angular/core';
import { ALL_CONSTANTS, API_ENDPOINT, FLAGS } from './config';
import { NativeStorage } from '@awesome-cordova-plugins/native-storage/ngx';
import { AlertController, LoadingController, ModalController, ToastController, Platform } from '@ionic/angular';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Events } from 'src/services/Events';
// import { GetData } from './GetData';

declare var window: any;
declare var cordova: any;
declare var navigator: any;

@Injectable()

export class appCommonMethods {
  public moengageNotificationClicked = new Subject();
  public moengageUserLogout = new Subject();
  public appPagesChages = new Subject();
  public showFooterSub = new Subject();
  fileUrl: any;
  fileName: any;
  public globaLoader = null;
  public noInternetModal;
  alertVar: any;

  public brochureCall = new BehaviorSubject(false);

  switchArray = [];

  constructor(public alertCtrl: AlertController, public loadingCtrl: LoadingController, public modalCtrl: ModalController, public events: Events, private toastCtrl: ToastController, public platform: Platform, public nativeStorage: NativeStorage, public router: Router, public translate: TranslateService,
    // public getPostData:GetData
    ) {
  }

  async showToast(title, position) {
    const toast = await this.toastCtrl.create({
      message: title,
      duration: 3000,
      position: position,
    });
    toast.present();
  }

  async showCustomToast(title, position) {
    const toast = await this.toastCtrl.create({
      message: title,
      duration: 3000,
      position: position,
      cssClass: 'custom-toast',
      color: 'success'
    });
    toast.present();
  }

  async showAlert(title, subTitle, buttonsText, roleAc) {
    const alert = await this.alertCtrl.create({
      header: title,
      subHeader: subTitle,
      buttons: [{
        text: buttonsText,
        role: 'null',
        handler: () => {
          this.alertVar.dismiss();
          this.events.publish('alertClose');
        }

      }]
    });
    await alert.present();
    this.events.publish('alertOpen');
  }

  dismissAlert() {
    if (this.alertVar) {
      this.alertVar.dismiss();
    }
  }

  baseURL(path, wantLive?) {
    if (!wantLive) {
      return ALL_CONSTANTS.BASE_URL + (path).trim();
    } else {
      return ALL_CONSTANTS.LIVE_BASE_URL + (path).trim();
    }

  }

  appendSiteUrl(context) {
    console.log(context);
    context = context.replace(/sites/g, ALL_CONSTANTS.BASE_URL + 'sites/');
    console.log(context);
    return context;
  }

  decodeHTMLEntities(text) {
    var entities = [
      ['amp', '&'],
      ['apos', '\''],
      ['#x27', '\''],
      ['#x2F', '/'],
      ['#39', '\''],
      ['#039', '\''],
      ['#x47', '/'],
      ['lt', '<'],
      ['gt', '>'],
      ['nbsp', ' '],
      ['quot', '"']
    ];

    if (text) {
      for (var i = 0, max = entities.length; i < max; ++i)
        text = text.replace(new RegExp('&' + entities[i][0] + ';', 'g'), entities[i][1]);
      return text;
    } else {
      return text;
    }
  }

  async showLoader(message?: string) {
    let content = message != null && message != '' && message != 'undefined' ? message : "Please wait...";
    if(!this.globaLoader){
      this.globaLoader = await this.loadingCtrl.create({
        message: content,
        spinner: 'crescent'
        // duration: 2000
      });
      return await this.globaLoader.present();
    }
  }

  hideLoader() {
    try {
      if (this.globaLoader) {
        this.globaLoader.dismiss().catch(err => console.log(err));
        this.globaLoader = null;
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  clearAllLocalStorage() {
    return new Promise((resolve, reject) => {
      try {
        window.localStorage.clear();
        resolve(true);
      } catch (e) {
        reject(e);
      }
    });
  }
  clearLocalStorageItem(key) {
    return new Promise((resolve, reject) => {
      try {
        window.localStorage.removeItem(key);
        resolve(true);
      } catch (e) {
        reject(e);
      }
    });
  }
  setLocalStorageItem(key, value) {
    return new Promise((resolve, reject) => {
      try {
        if(key == 'userData'){
          console.log(' Saved user Data here @@@')
        }
        window.localStorage.setItem(key, JSON.stringify(value));
        resolve(true);
      } catch (e) {
        reject(e);
      }
    });
  }
  setLocalStorageSingleItem(key, value) {
    return new Promise((resolve, reject) => {
      try {
        window.localStorage.setItem(key, value);
        resolve(true);
      } catch (e) {
        reject(e);
      }
    });
  }
  getLocalStorageItem(key) {
    return new Promise((resolve, reject) => {
      try {
        let resJson = window.localStorage.getItem(key);
        let resData = JSON.parse(resJson) ? JSON.parse(resJson) : [];
        resolve(resData);
      } catch (e) {
        reject(e);
      }
    });
  }

  getLocalStorageMulti(key) {
    if (window.localStorage.getItem(key)) {
      return JSON.parse(window.localStorage.getItem(key));
    } else {
      return false;
    }
  }

  getLocalStorageSingleItem(key) {
    return new Promise((resolve, reject) => {
      try {
        let resData = window.localStorage.getItem(key);
        resolve(resData);
      } catch (e) {
        reject(e);
      }
    });
  }
  removeStackPages(nav: any, count: number) {
    nav.remove(nav.getActive().index - count, count);
  }

  checkNetwork() {
    return new Promise((resolve, reject) => {
      console.log("platform ", this.platform.is('cordova'));
      if (this.platform.is('cordova')) {
        var networkState = navigator.connection.type;
        console.log('networkState', networkState);
        if (networkState == 'none') {
          resolve(false);
        } else {
          let restRet = "online";
          resolve(restRet);
        }
      } else {
        /* check the network connection using HTML5 API */
        if (navigator.onLine) {
          let restRet = "online";
          resolve(restRet);
        } else {
          resolve(false);
        }
      }
    });
  }

  titleCase(str) {
    console.log("str", str);
    if (str) {
      var splitStr = str.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(' ');
    }
    else {
      return str;
    }
  }

  numberToMoney(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  validateElement(type, val) {
    if (type == 'email') {
      let regEx = new RegExp("[^@]+@[^@]+\.[a-zA-Z]{2,}");
      if (regEx.test(val)) {
        return true;
      } else {
        return false;
      }
    } else if (type == 'name') {
      let regEx = new RegExp("^[a-zA-Z '\s-]+$");
      if ((val).trim() != "") {
        if (regEx.test(val)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else if (type == 'number') {
      let regEx = new RegExp("^[0-9]*$");
      if (regEx.test(val)) {
        return true;
      } else {
        return false;
      }
    } else if (type == 'mobile number') {
      let regEx = new RegExp("[6-9]{1}[0-9]{9}");
      if (regEx.test(val)) {
        return true;
      } else {
        return false;
      }
    } else if (type == "website") {
      let regEx = new RegExp("^((http(s)?:\/\/(www\.))|(www\.))?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$");
      if (regEx.test(val)) {
        return true;
      } else {
        return false;
      }
    } else if (type == "linkedIn") {
      let regEx = /(https?)?:?(\/\/)?(([w]{3}||\w\w)\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
      // let regEx = new RegExp("(https?)?:?(\/\/)?(([w]{3}||\w\w)\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?");
      if (regEx.test(val)) {
        return true;
      } else {
        return false;
      }
    } else if (type == "facebook") {
      let regEx = new RegExp("(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?");
      if (regEx.test(val)) {
        return true;
      } else {
        return false;
      }
    } else if (type == "twitter") {
      let regEx = new RegExp("(?:http:\/\/)?(?:www\.)?twitter\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)");
      if (regEx.test(val)) {
        return true;
      } else {
        return false;
      }
    } else if (type == "instagram") {
      let regEx = new RegExp("(?:http:\/\/)?(?:www\.)?instagram\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)");
      if (regEx.test(val)) {
        return true;
      } else {
        return false;
      }
    } else if (type == "skype") {
      let regEx = new RegExp("^[a-zA-Z0-9]+([_ -]?[a-zA-Z0-9])*$");
      let regEx2 = new RegExp("^(?:live|Live)+([:]?[ a-zA-Z0-9])?([^@]+@[^@]+\.[a-zA-Z]{2,})?$");
      let regEx3 = new RegExp("(?:live|Live)+([:]?[ a-zA-Z0-9])*$");
      if (regEx.test(val) || regEx2.test(val) || regEx3.test(val)) {
        return true;
      } else {
        return false;
      }
    } else if (type == 'integer_floating') {
      let regEx = new RegExp("^\\d*(\\.)?(\\d{1,2})?$");
      if (regEx.test(val)) {
        return true;
      } else {
        return false;
      }
    }
  }

  formatUserData(data, searchedUserRole, usrData) {
    return new Promise((res, rej) => {
      if (data) {
        let fullNameSel = data.field_name;
        let emailIdSel = data.mail;
        let companyNameSel = data.field_company_name;
        let companyAddressSel = data.field_company_address;
        let pincodeSel = data.field_pincode;
        let gstnNoSel = data.field_gstn_number;
        let birthDateSel = data.field_date_of_birth;
        let anniversaryDateSel = data.field_anniversary_date;
        let dateOfIncorporationSel = data.field_anniversary_date;
        let prioritySel = data.field_type_of_business;
        let photo = "";
        let pancardStatus = "";
        let call_details = "";
        let aso_status = "";
        let t2UserType = "";
        let taggedT3 = "";
        let pancardImg = "";
        let sales = "";
        let rebates = "";
        let doYouSell = "";
        let focus_category = "";
        let sub_category = "";
        let hp = "";
        let lenovo = "";
        let dell = "";
        let others = "";
        let profileChanged = "";
        let updateRef = "";
        let contract_no = "";
        let category = "";
        let t2ShopId = "";

        if (anniversaryDateSel == '' || anniversaryDateSel == '1970-01-01') {
          anniversaryDateSel = '';
        }

        if (dateOfIncorporationSel == '' || dateOfIncorporationSel == '1970-01-01') {
          dateOfIncorporationSel = '';
        }

        let stateSel = data.state;
        let citySel = data.city;

        if ((data.user_picture) && (data.user_picture).length > 0) {
          photo = ALL_CONSTANTS.BASE_URL + '/' + data.user_picture;
        }
        let profileStatus = data.field_profile_verification_statu;

        let manage_user_type = data.field_user_type;
        let manage_owner_id = data.owner_id;

        // if pan image is uploaded
        if (data.field_pancard_image && data.field_pancard_image != '') {
          pancardImg = ALL_CONSTANTS.BASE_URL + '/' + data.field_pancard_image;
        }
        console.log(data.pan_image, data, 'data profile');


        if (!data.pan_apprfield_pancard_approvaloval) {
          pancardStatus = "Pending";
        }
        else {
          pancardStatus = data.field_pancard_approval;
        }

        let pan_rejection_reason = data.field_pan_rejection_reason;

        // if this user is verified on the call by the lenovo team
        if (data.field_call_details) {
          call_details = data.field_call_details;
        }
        let call_verification = data.field_call_verification;
        let call_verification_date = data.field_call_verification_date;

        if (data.field_aso_status) {
          aso_status = data.field_aso_status;
        }

        //this is for checking T2 user type
        if (data.field_t2_type) {
          t2UserType = data.field_t2_type;
        }

        //this is for checking Tagged T3 user
        if (data.field_t3_user) {
          taggedT3 = data.field_t3_user;
        }
        data.roles_target_id = data.roles_target_id ? (data.roles_target_id).toLowerCase() : "";
        if (searchedUserRole == "t3") {
          data.field_user_type = (data['field_user_type']).toLowerCase();
          if (data.field_user_type != 'employee' && data.field_user_type != 'coowner') {
            data.roles_target_id = data.roles_target_id;
          }
          else if (data.field_user_type == 'employee') {
            data.roles_target_id = 'Employee';
          }
          else if (data.field_user_type == 'coowner') {
            data.roles_target_id = 'Co-Owner';
          }
        }

        if (usrData['field_customer_s_focus_category']) {
          focus_category = usrData['field_customer_s_focus_category'];
        }

        if (usrData['field_customer_s_sub_category_']) {
          sub_category = usrData['field_customer_s_sub_category_'];
        }

        if (usrData['field_do_you_have_any_rebates_or']) {
          rebates = usrData['field_do_you_have_any_rebates_or'];
        }

        if (usrData['field_do_you_sell']) {
          doYouSell = usrData['field_do_you_sell'];
        }

        if (usrData['field_number_of_sales_persons']) {
          sales = usrData['field_number_of_sales_persons'];
        }

        if (usrData['field_dell']) {
          dell = usrData['field_dell'];
        }

        if (usrData['field_lenovo']) {
          lenovo = usrData['field_lenovo'];
        }

        if (usrData['field_others']) {
          others = usrData['field_others'];
        }

        if (usrData['field_hp']) {
          hp = usrData['field_hp'];
        }

        if (data.field_profile_changed) {
          profileChanged = data.field_profile_changed;
        }

        if (data.update_ref_id) {
          updateRef = data.update_ref_id;
        }

        if (data.field_t2_category) {
          category = data.field_t2_category;
        }

        if (data.field_t2_contract_no) {
          contract_no = data.field_t2_contract_no;
        }
        var birthDate: any = new Date(birthDateSel);
        // birthDate = birthDate.getFullYear()+"-"+(birthDate.getMonth()+1)+"-"+birthDate.getDate();
        birthDate = "2000-" + (birthDate.getMonth() + 1) + "-" + birthDate.getDate();

        if (anniversaryDateSel) {
          var anniversaryDate: any = new Date(anniversaryDateSel);
          // anniversaryDate = anniversaryDate.getFullYear()+"-"+(anniversaryDate.getMonth()+1)+"-"+anniversaryDate.getDate();
          anniversaryDate = "1970-" + (anniversaryDate.getMonth() + 1) + "-" + anniversaryDate.getDate();
        }

        if (dateOfIncorporationSel) {
          var date_of_incorporation: any = new Date(dateOfIncorporationSel);
          date_of_incorporation = date_of_incorporation.getFullYear() + "-" + (date_of_incorporation.getMonth() + 1) + "-" + date_of_incorporation.getDate();
        }

        if (data.field_t2_shop_details) {
          t2ShopId = data.field_t2_shop_details;
        }

        let totalPoints = 0;
        let totalRedeemedPoints = 0;
        let estimated_point = 0;

        if (searchedUserRole == 't2') {
          totalPoints = data.t2_total_points ? data.t2_total_points : 0;
          totalRedeemedPoints = data.t2_redeemed_points ? data.t2_redeemed_points : 0;
          estimated_point = data.t2_estimated_points ? data.t2_estimated_points : 0;
          data.currentPoints = totalPoints - totalRedeemedPoints;
        } else {
          totalPoints = data.field_total_points ? data.field_total_points : 0;
          totalRedeemedPoints = data.field_total_redeem_point ? data.field_total_redeem_point : 0;
          estimated_point = data.field_estimated_point ? data.field_estimated_point : 0;
          data.currentPoints = totalPoints - totalRedeemedPoints;
        }
        console.log('curp', data.currentPoints, 'total', totalPoints, 'redeem', totalRedeemedPoints, 'verifyotp');

        let shopId = "";
        if (searchedUserRole == 't2') {
          if (data.field_t2_shop_details) {
            shopId = data.field_t2_shop_details;
          }
        } else {
          if (data.shop_id) {
            shopId = data.shop_id;
          }
        }
        let userData = {
          'name': fullNameSel, 'company_name': companyNameSel, 'company_address': companyAddressSel, 'pincode': pincodeSel, 'email': emailIdSel, 'gstn_no': gstnNoSel, 'referral_code': '', 'userId': data.uid, 'role': data.roles_target_id, 'mobile': data.field_mobile_number, "cityName": data.field_city, "stateName": data.field_state, "city": data.city_id, "state": data.state_id, "points": totalPoints, "estimated_point": estimated_point, 'newProfile': 0, 'photo': photo, 'priority': prioritySel, 'profileStatus': profileStatus, 'pan_image': pancardImg, 'pan_status': this.titleCase(pancardStatus), 'pan_rejection_reason': pan_rejection_reason, 'call_details': call_details, 'call_status': this.titleCase(call_verification), 'call_verified_on': call_verification_date, 'aso_status': this.titleCase(aso_status), 'birth_date': birthDate, 'anniversary_date': anniversaryDate, 'date_of_incorporation': date_of_incorporation,
          'regionMapping': data.regionMapping ? data.regionMapping : data.field_region, 'stateMapping': data.stateMapping, 'cityMapping': data.cityMapping, 'manage_user_type': manage_user_type, 'manage_owner_id': manage_owner_id, "currentPoints": data.currentPoints, "redeemed_points": data.field_total_redeem_point, 'type_of_business': data.field_type_of_business, "t2_user_type": t2UserType, "t3_user_tagged": taggedT3,
          "field_customer_s_focus_category": focus_category,
          "field_customer_s_sub_category_": sub_category,
          "field_number_of_sales_persons": sales,
          "field_do_you_sell": doYouSell,
          "field_do_you_have_any_rebates_or": rebates,
          "field_lenovo": lenovo,
          "field_hp": hp,
          "field_dell": dell,
          "field_others": others,
          "shop_id": shopId, 'field_profile_changed': profileChanged, 'update_ref_id': updateRef,
          "field_t2_category": category, "field_t2_contract_no": contract_no, "t2_shopId": t2ShopId
        };
        console.log("After switch user data - " + userData);

        if (searchedUserRole == "t3") {
        } else if (searchedUserRole == "t2") {
        }
        this.hideLoader();
      }
    });
  }


  async showPopAlert(title, subTitle, buttonsText, role) {
    console.log("Role rec", role);
    if (role) {
      role = role.toLowerCase();
    } else {
      role = 't3';
    }
    let t3Label = "switch-to-t3";
    let t2Label = "switch-to-t2";
    let cancel = "cancel";
    let switchCopy = "switch";

    this.translate.get(t3Label).subscribe((data) => {
      t3Label = data;
    }, (error) => {
    });

    this.translate.get(t2Label).subscribe((data) => {
      t2Label = data;
    }, (error) => {
    });

    this.translate.get(cancel).subscribe((data) => {
      cancel = data;
    }, (error) => {
    });

    this.translate.get(switchCopy).subscribe((data) => {
      switchCopy = data;
    }, (error) => {
    });

    console.log("Role rec after", role);
    const alert = await this.alertCtrl.create({
      header: 'Select Role',
      buttons: [
        {
          text: cancel,

          role: 'null',
          handler: () => {
            alert.dismiss();
          }

        },
        {
          text: switchCopy,
          role: 'null',
          handler: (data) => {
            console.log("pop data", data);
            alert.dismiss();
            let currUrl = this.router.url
            this.events.publish('roleAlertClosed', true, data, currUrl);
          }

        }
      ],
      inputs: [
        {
          name: 'T2',
          placeholder: 'T2',
          label: t2Label,
          type: 'radio',
          value: 't2',
          checked: role == 't2' ? true : false
        },
        {
          name: 'T3',
          placeholder: 'T3',
          label: t3Label,
          type: 'radio',
          value: 't3',
          checked: role != 't2' ? true : false
        }
      ]
    });
    await alert.present();
  }

  async showDummyLoader(message?: string) {
    let content = message != null && message != '' && message != 'undefined' ? message : "Switching Profile please wait...";
    let loader = await this.loadingCtrl.create({
      message: content,
      spinner: 'crescent',
      duration: 2000
    });
    return await loader.present();
  }

  getFormattedDate(rawDate, dateFormat, seperator?) {
    return new Promise((res, rej) => {
      if (rawDate) {
        if (dateFormat == 'ddmmyyyy') {
          let date = new Date(rawDate);
          let day: any = date.getDate();
          let month: any = date.getMonth() + 1;
          let year: any = date.getFullYear();

          if (seperator) {
            seperator = seperator.trim();
            month = (month > 9) ? month : '0' + month;
            day = (day > 9) ? day : '0' + day;
            res(day + seperator + month + seperator + year);
          } else {
            month = (month > 9) ? month : '0' + month;
            day = (day > 9) ? day : '0' + day;
            res(day + '/' + month + '/' + year);
          }
        }
        else if (dateFormat == 'yyyymmdd') {
          let date = new Date(rawDate);
          let day: any = date.getDate();
          let month: any = date.getMonth() + 1;
          let year: any = date.getFullYear();

          if (seperator) {
            seperator = seperator.trim();
            month = (month > 9) ? month : '0' + month;
            day = (day > 9) ? day : '0' + day;
            res(year + seperator + month + seperator + day);
          } else {
            month = (month > 9) ? month : '0' + month;
            day = (day > 9) ? day : '0' + day;
            res(year + '/' + month + '/' + day);
          }
        }
      } else {
        res(false);
      }
    });
  }


  async loaderCtrl(message?: string) {
    let content = message != null && message != '' && message != 'undefined' ? message : "Please wait...";
    return this.loadingCtrl.create({
      message: content,
      spinner: 'crescent'
      // duration: 2000
    });
    // return await  this.globaLoader.present();
  }

  isRoleCheck(role) {
    // console.log("usrflag",FLAGS.userFlag, role);
    /*if(FLAGS.userFlag.toLowerCase() == 'aso' && role.toLowerCase() == 'aso' ){
      return "aso";
    }else if(FLAGS.userFlag.toLowerCase() == 't3' || FLAGS.userFlag.toLowerCase() == 'lenovo partner' && role.toLowerCase() == 't3' || role.toLowerCase() == 'lenovo partner'){
      return "t3";
    }else if(FLAGS.userFlag.toLowerCase() == 'srd' && role.toLowerCase() == 'srd' ){
      return "srd";
    }
  } */


    if (FLAGS.userFlag.toLowerCase() == 'aso' || role.toLowerCase() == 'aso') {
      return "aso";
    } else if (FLAGS.userFlag.toLowerCase() == 't3' || FLAGS.userFlag.toLowerCase() == 'lenovo partner' || role.toLowerCase() == 't3' || role.toLowerCase() == 'lenovo partner') {
      return "t3";
    } else if (FLAGS.userFlag.toLowerCase() == 't2' || role.toLowerCase() == 't2') {
      return "t2";
    } else if (FLAGS.userFlag.toLowerCase() == 'srd' || role.toLowerCase() == 'srd') {
      return "srd";
    } else if (FLAGS.userFlag.toLowerCase() == 'rsm' || role.toLowerCase() == 'rsm') {
      return "rsm";
    } else if (FLAGS.userFlag.toLowerCase() == 'rcm' || role.toLowerCase() == 'rcm') {
      return "rcm";
    } else if (FLAGS.userFlag.toLowerCase() == 'lep' || role.toLowerCase() == 'lep') {
      return "lep";
    } else if (FLAGS.userFlag.toLowerCase() == 't2_sales_head' || role.toLowerCase() == 't2_sales_head') {
      return "t2_sales_head";
    } else if (FLAGS.userFlag.toLowerCase() == 'lenovo_management' || role.toLowerCase() == 'lenovo_management' || FLAGS.userFlag.toLowerCase() == 'lenovo management' || role.toLowerCase() == 'lenovo management') {
      return "lenovo management";
    }
  }

  unescapeHtml(safe) {
    if (safe) {
      return safe.replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&quot;/g, '"')
        .replace(/&#039;/g, "'");
    } else {
      return "";
    }

  }

  isRoleCheckSync(role) {
    return new Promise((res, rej) => {
      if (role || FLAGS.userFlag) {
        if (role.toLowerCase() == 'aso') {
          res("aso");
        } else if (role.toLowerCase() == 't3' || role.toLowerCase() == 'lenovo partner') {
          res("t3");
        } else if (role.toLowerCase() == 't2') {
          res("t2");
        } else if (role.toLowerCase() == 'srd') {
          res("srd");
        } else if (role.toLowerCase() == 'rsm') {
          res("rsm");
        } else if (role.toLowerCase() == 'rcm') {
          res("rcm");
        } else if (role.toLowerCase() == 'lep') {
          res("lep");
        } else if (role.toLowerCase() == 't2_sales_head') {
          res("t2_sales_head");
        } else if (role.toLowerCase() == 'lenovo_management' || role.toLowerCase() == 'lenovo management') {
          res("lenovo management");
        }
      } else {
        res("");
      }

    });

  }

  async showRolePopUp(res?) {
    return new Promise( (resolve, reject) => {
      let switcher = [];
        console.log('datsa : ', res, this.switchArray)
        var switchArray1 = [...this.switchArray];
        
        this.getLocalStorageItem('userData').then( (userData:any) => {
          switchArray1.filter((item, index) => {
            if(item == userData.data.role.toLowerCase()+'-'+userData.data.aso_type.toLowerCase()){
              this.switchArray.splice(index,1);
            }
          });
          this.getLocalStorageItem('roleBaseConfig').then(async (resData: any) => {
            console.log('Switch Array : ', this.switchArray)
            for (var i = 0; i < this.switchArray.length; i++) {
              resData.filter(itemData => {
                if (itemData.label == this.switchArray[i]) {
                  switcher.push({
                    name: res[itemData.label],
                    type: 'radio',
                    label:res[itemData.label],
                    value: itemData.userID ? itemData.userID : itemData.userId,
                    handler: (val) => {
                      console.log(`Radio $i selected`, val, val.value);
                      
                    },
                    checked: false,
                  })
                }
                
              })
            }
            const alert = await this.alertCtrl.create({
              cssClass: 'my-custom-class',
              header: 'Switch to',
              inputs: switcher,
              buttons: [
                {
                  text: 'Cancel',
                  role: 'cancel',
                  cssClass: 'secondary',
                  handler: () => {
                    console.log('Confirm Cancel');
                  },
                },
                {
                  text: 'Ok',
                  handler: (val) => {
                    console.log('Confirm Ok', val, val.value);
                    resolve(val);
                  },
                },
              ],
            });
            await alert.present();
          });
        })
    });
  }

  isValidImage(filename: any) {  
    if(filename.toLowerCase().endsWith(".png") || filename.toLowerCase().endsWith(".jpg") || filename.toLowerCase().endsWith(".jpeg")){
      return true;
    }else{
      return false;
    }
  }

  
}